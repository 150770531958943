/**
 * Luma Group
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Menu controller.
 */
export default class extends Controller {
  /**
   * On scroll.
   */
  onScroll() {
    const scrollThreshold = window.innerWidth > 1024 ? 200 : 10;
    this.element.classList.toggle('has-scrolled', window.scrollY >= scrollThreshold);
  }

  /**
   * Toggle menu.
   */
  toggleMenu() {
    this.element.classList.toggle('menu-open');
  }
}
