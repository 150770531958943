// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-96x96.png';
import '../assets/img/svg/cursor.svg';
import '../assets/img/svg/cursor-play.svg';
import '../assets/img/svg/icon-arrow-right.svg';
import '../assets/img/svg/icon-whatsapp.svg';
import '../assets/img/svg/icon-cross.svg';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-external.svg';
import '../assets/img/svg/icon-search.svg';
import '../assets/img/svg/icon-crosshair.svg';
import '../assets/img/svg/icon-plus.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-mail.svg';
import '../assets/img/svg/icon-star.svg';
import '../assets/img/svg/icon-location.svg';
import '../assets/img/svg/icon-filters.svg';
import '../assets/img/svg/icon-chevron-down.svg';
import '../assets/img/svg/icon-alert.svg';
import '../assets/img/svg/icon-rocket.svg';
import '../assets/img/svg/icon-list.svg';
import '../assets/img/svg/icon-map.svg';
import '../assets/img/svg/icon-arrow-left.svg';
import '../assets/img/svg/icon-check.svg';
import '../assets/img/svg/icon-upload.svg';
import '../assets/img/footer-bg.jpg';
import '../assets/img/footer-bg-mobile.jpg';

import '../assets/img/signature/email.png';
import '../assets/img/signature/instagram.png';
import '../assets/img/signature/linkedin.png';
import '../assets/img/signature/location.png';
import '../assets/img/signature/logo.png';
import '../assets/img/signature/mobile.png';
import '../assets/img/signature/phone.png';
import '../assets/img/signature/website.png';

// Logo's
import '../assets/img/logo/logo-luma.svg';
import '../assets/img/logo/logo-luma-green.svg';
import '../assets/img/logo/logo-email.png';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
