/**
 * Luma Group
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';

/**
 * GSAP controller.
 */
export default class extends Controller {
  static targets = ['fadeIn', 'logo', 'filmRoll1', 'filmRoll2', 'intro'];

  /**
   * GSAP context.
   */
  gsapContext;

  /**
   * Initialize
   */
  initialize() {
    this.gsapContext = gsap.context(() => { });
  }

  /**
   * Connect.
   */
  connect() {
    if (this.hasFilmRoll1Target) {
      this.gsapContext.add(() => {
        const timeline = gsap.timeline();

        gsap.set(this.element, { overflow: 'hidden' });
        gsap.set(this.fadeInTargets, { opacity: 0 });

        timeline.eventCallback('onComplete', () => {
          gsap.set(this.element, { clearProps: 'overflow' });
          gsap.set(this.fadeInTargets, { clearProps: 'opacity' });
          this.introTarget.classList.remove('intro-grid-active');
          window.scrollTo(0, 0);

          gsap.fromTo(this.fadeInTargets, {
            opacity: 0,
          }, {
            opacity: 1,
            duration: 0.75,
            ease: 'linear',
            delay: 0.5,
          });
        });

        timeline.fromTo(this.filmRoll1Target, {
          yPercent: 100,
          opacity: 0,
        }, {
          yPercent: -10,
          opacity: 1,
          duration: 2.5,
          ease: 'power4.inOut',
          force3D: true,
        }, 0.5);

        timeline.fromTo(this.filmRoll2Target, {
          yPercent: -125,
          opacity: 0,
        }, {
          yPercent: -10,
          opacity: 1,
          duration: 2.5,
          ease: 'power4.inOut',
          force3D: true,
        }, 0.5);

        timeline.fromTo(this.logoTarget, {
          opacity: 1,
        }, {
          opacity: 0,
          duration: 0.75,
          ease: 'linear',
          force3D: true,
        }, '-=0.75');

        // If the current url has x-craft-preview or x-craft-live-preview search param,
        // then we want to skip the intro animation
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('x-craft-preview') || urlParams.has('x-craft-live-preview')) {
          timeline.progress(1);
        }
      });
    }
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
