/**
 * Luma Group
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * GSAP controller.
 */
export default class extends Controller {
  static targets = ['dimmerItem'];

  /**
   * GSAP context.
   */
  gsapContext;

  /**
   * Initialize
   */
  initialize() {
    this.gsapContext = gsap.context(() => {});
  }

  /**
   * Connect.
   */
  connect() {
    this.gsapContext.add(() => {
      const items = this.hasDimmerItemTarget
        ? gsap.utils.toArray(this.dimmerItemTargets)
        : undefined;

      if (items) {
        const totalItems = items.length;
        const dimmer = gsap
          .timeline()
          .to(items.slice(1), {
            opacity: 1,
            stagger: 0.5,
          })
          .to(
            items.slice(0, totalItems - 1),
            {
              opacity: 0.2,
              stagger: 0.5,
            },
            0,
          );

        ScrollTrigger.create({
          trigger: items[0],
          endTrigger: items[totalItems - 1],
          start: 'center center',
          end: 'center center',
          animation: dimmer,
          scrub: 0.25,
        });
      }
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
