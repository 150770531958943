/**
 * Luma Group
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple toggle controller
 */
export default class extends Controller {
  /**
   * @var {Array}
   */
  static targets = ['input'];

  /**
   * @var {Array}
   */
  static classes = ['active'];

  /**
   * Toggle target class.
   */
  toggle() {
    this.element.classList.toggle(this.activeClass);

    if (this.hasInputTarget) setTimeout(this.inputTarget.focus.bind(this.inputTarget), 0);
  }
}
