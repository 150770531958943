/**
 * Luma Group
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';

/**
 * GSAP controller.
 */
export default class extends Controller {
  /**
   * GSAP context.
   */
  gsapContext;

  /**
   * Initialize
   */
  initialize() {
    this.gsapContext = gsap.context(() => {});
  }

  /**
   * Connect.
   */
  connect() {
    this.gsapContext.add(() => {
      gsap.fromTo(
        this.element,
        {
          clipPath: 'inset(10% 25% 10% 25% round 6rem 0 6rem 6rem)',
        },
        {
          clipPath: 'inset(0% 0% 0% 0% round 3rem 0 3rem 3rem)',
          duration: 1.8,
          ease: 'power4.out',
          force3D: true,
          scrollTrigger: {
            trigger: this.element,
            start: 'top center',
          },
        },
      );
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
