/**
 * Luma Group
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { Swiper } from 'swiper';
import {
  Autoplay, Navigation, EffectFade, Pagination,
} from 'swiper/modules';

Swiper.use([Autoplay, Navigation, EffectFade, Pagination]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper'];

  static values = {
    options: Object,
    type: {
      type: String,
      default: 'default',
    },
  };

  swiper;

  /**
   * Init.
   */
  initialize() {
    const options = {
      slidesPerView: 'auto',
      ...this.optionsValue,
    };

    if (this.typeValue === 'fraction') {
      options.pagination = {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction(currentClass, totalClass) {
          return `<span class="${currentClass}"></span>`
            + ' van '
            + `<span class="${totalClass}"></span>`;
        },
      };
    }

    this.swiper = new Swiper(this.wrapperTarget, options);
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Advances to the next slide in the Swiper instance.
   */
  slideNext() {
    this.swiper.slideNext();
  }

  /**
   * Slides the swiper to a specific index
   * @param {Event} e - The event object from the click/interaction
   */
  slideTo(e) {
    this.swiper.slideTo(e.currentTarget.dataset.index);
  }

  /**
  * Disconnect swiper.
  */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
