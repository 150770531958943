/**
 * Luma Group
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';

/**
 * GSAP controller.
 */
export default class extends Controller {
  static targets = ['glowItem'];

  /**
   * GSAP context.
   */
  gsapContext;

  /**
   * Initialize
   */
  initialize() {
    this.gsapContext = gsap.context(() => {});
  }

  /**
   * Connect.
   */
  connect() {
    this.gsapContext.add(() => {
      if (this.hasGlowItemTarget) {
        const setPosition = gsap.quickSetter(this.glowItemTarget, 'css');
        this.element.addEventListener('mousemove', (event) => {
          const x = event.pageX - this.element.offsetLeft;
          const y = event.pageY - this.element.offsetTop;

          setPosition({ x, y });
        });
      }
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
